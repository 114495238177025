@font-face {
  font-family: 'lo-res-12';
  src: local('lo-res-12'),
    url('./fonts/lo-res12regular/LoRes12OT-Reg.ttf')
    format('truetype');
}

@font-face {
  font-family: 'lo-res-12-narrow';
  src: local('lo-res-12'),
    url('./fonts/lo-res12narrow/LoRes12OT-NarReg.ttf')
    format('truetype');
}

@font-face {
  font-family: 'lo-res-15';
  src: local('lo-res-15'),
    url('./fonts/lo-res15boldboldalternat/LoRes15OT-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'lo-res-15-bold';
  src: local('lo-res-15-bold'),
    url('./fonts/lo-res15boldboldalternat/LoRes15OT-BoldAltOakland.ttf')
      format('truetype');
}

@font-face {
  font-family: 'regule5';
  src: local('regule5'),
    url('./fonts/Regule5/Regule5.otf')
      format('truetype');
}

@font-face {
  font-family: 'regule5-bold';
  src: local('regule5-bold'),
    url('./fonts/Regule5/Regule5-Bold.otf')
      format('truetype');
}

.connectWallet:active {
  /* background-color: #3e8e41; */
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
.connectWallet{
  cursor: pointer;
}